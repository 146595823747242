<template>
  <div class="about">
    <div class="about__intro">
      <div class="text">
        <h1>Клуб настоящих ценителей вечеринок!</h1>
        <router-link to="products/PageCatalog">Product</router-link>
        <h2>
          Акции, розыгрыши, горячие скидки на алкоголь и табачную продукцию,
          бонусы с каждой покупки на карту Magnum Club
        </h2>
      </div>
      <div class="pic">
        <img src="@/assets/img/about/about.png" alt="" />
      </div>
    </div>
    <div class="about__reasons">
      <div class="title">
        <hr />
        <p>Почему Party Cub?</p>
      </div>
      <div class="content">
        <div v-for="(reason, index) in reasons" :key="index">
          <div class="placeholder"></div>
          <p>
            {{ reason.txt }}
          </p>
        </div>
      </div>
    </div>
    <div class="about__instr">
      <div class="title">
        <p>Как стать участником клуба?</p>
      </div>
      <div class="content">
        <div v-for="(instr, index) in instrs" :key="index">
          <p class="digits">{{ instr.num }}</p>
          <p>
            {{ instr.txt }}
          </p>
        </div>
      </div>
    </div>
    <div class="about__brands">
      <div class="title"><p>Наши бренды</p></div>
      <div class="about__brands__content">
        <img v-for="(pic, index) in pics" :key="index" :src="pic.img" alt="" />
      </div>
    </div>
    <div class="about__request">
      <button class="mobile">Вступить в клуб</button>
      <div class="about__request__pic"></div>
      <div class="about__request__content">
        <p class="title">Мы ждем вас в клубе!</p>
        <p class="request__text">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem
          ipsum dolor sit amet, consectetur adipiscing elit.
        </p>
        <button class="desk">Вступить в клуб</button>
      </div>
    </div>
    <div class="warning">
      <div class="warning__content">
        <div class="age">21+</div>
        <p class="warning__content__text">
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi
          molestiae ad, recusandae praesentium incidunt architecto illum, fuga
          possimus soluta sint voluptatum rem voluptas animi.
        </p>
      </div>
    </div>
    <main-product />
  </div>
</template>

<script>
import MainProduct from "../../components/products/MainProduct.vue";
export default {
  components: {  MainProduct},
  name: "AboutParty",
  data() {
    return {
      reasons: [
        {
          txt: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        },
        {
          txt: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        },
        {
          txt: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        },
      ],
      instrs: [
        {
          num: "01",
          txt: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        },
        {
          num: "02",
          txt: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        },
        {
          num: "03",
          txt: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        },
      ],
      pics: [
        { img: require("@/assets/img/about/bat.png") },
        { img: require("@/assets/img/about/monkey.png") },
        { img: require("@/assets/img/about/jelzin.png") },
        { img: require("@/assets/img/about/tiger.png") },
        { img: require("@/assets/img/about/carlsberg.png") },
        { img: require("@/assets/img/about/bat.png") },
        { img: require("@/assets/img/about/monkey.png") },
        { img: require("@/assets/img/about/jelzin.png") },
        { img: require("@/assets/img/about/tiger.png") },
        { img: require("@/assets/img/about/carlsberg.png") },
        { img: require("@/assets/img/about/bat.png") },
        { img: require("@/assets/img/about/monkey.png") },
        { img: require("@/assets/img/about/jelzin.png") },
        { img: require("@/assets/img/about/tiger.png") },
        { img: require("@/assets/img/about/carlsberg.png") },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.recom {
  display: flex;
}
@media screen and (max-width: 768px) {
  .recom {
    flex-direction: column;
  }
}
</style>
